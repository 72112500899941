import { colors } from '../../../shared/colors';
import { RadioGroup } from '@headlessui/react';
import { Button } from '@mui/material';
import { ListItem } from './list-item';
import { btnStyle } from '../../../shared/helpers/helper';
import React from 'react';
import { RadioOption } from './radio-option';

export const MoreInfo = ({vehicle, choice, setChoice, handlePrequalify}: any): React.JSX.Element => {
    return (
        <div
            id='modal'
            className='bg-white shadow-2xl shadow-black w-fit p-5'
            style={{position: 'absolute', zIndex: 1, left: 0, right: 0, marginTop: -180, marginLeft: 'auto', marginRight: 'auto'}}
        >
            <h1 style={{color: colors.primary}} className='font-bold my-2'>{vehicle.make} {vehicle.range}</h1>
            <RadioGroup value={choice} className='grid grid-cols-1 md:grid-cols-2 gap-3' onChange={(value) => setChoice(value)}>
                {/* WEB */}
                <div className='hidden md:block'>{vehicle.options.length > 0 && <RadioOption option={vehicle?.options[0]} />}</div>
                <div className='hidden md:block'>{vehicle.options.length > 2 && <RadioOption option={vehicle?.options[2]} />}</div>
                <div className='hidden md:block'>{vehicle.options.length > 1 && <RadioOption option={vehicle?.options[1]} />}</div>
                <div className='hidden md:block'>{vehicle.options.length > 3 && <RadioOption option={vehicle?.options[3]} />}</div>
                <div className='hidden md:block'>{vehicle.options.length > 4 && <RadioOption option={vehicle?.options[4]} />}</div>
                
                {/* MOBILE */}
                {
                    vehicle.options.map((option: any, index: number) => (
                        <div key={index} className='md:hidden'>
                            <RadioOption option={option} />
                        </div>
                    ))
                }
                <h1 style={{color: colors.primary}} className='font-bold mt-3 mb-2 col-span-full'>What you get</h1>
                <ListItem text='The new vehicle of your choice leased to you.' />
                <ListItem text='Compulsory comprehensive insurance quoted seperately.' />
                <ListItem text='Maintenance and tyres (normal wear) included and billed to Avis directly.' />
                <ListItem text='End of contract, we resell it at our risk.' />
                <ListItem text='Annual vehicle license (we send it to you).' />
                <ListItem text='Roadside assistance.' />
                <ListItem text='Telematics device.' />
                <ListItem text='Flexible usage contract to fit your needs.' />
            </RadioGroup>
            <div className='border-t mt-5'>
                <Button
                    onClick={handlePrequalify}
                    style={{...btnStyle as any}}
                >
                    Pre-qualify
                </Button>
            </div>
        </div>
    );
};
