import React from 'react'
import { Vehicles } from '../data/data';
import PriceCompareCard from '../components/price-compare-card';

export const PriceComparison = (): React.JSX.Element => {
  
    React.useEffect(() => {
      window.scrollTo(0, 0);
    });
    
    return (
        <>
            <h1 className='text-red-600 font-bold uppercase'>Price Comparison</h1>
            <p className='mt-5 text-gray-500'>
                Your individualised Avis iLease monthly payment includes the cost of financing, comprehensive insurance 
                (qouted and charged separately) and maintenance (including tyres). Other costs, such as "on the road costs" 
                and annual licensing fees are also included.
            </p>
            <div className='grid grid-cols-1 md:grid-cols-3 gap-5 my-5'>
                { Vehicles.map((vehicle: any, index: number) => <PriceCompareCard key={index} vehicle={vehicle} />) }
            </div>
        </>
    );
};
