import React from 'react'
import { colors } from '../../../shared/colors';
import { useAppDispatch } from '../../../redux/store';
import { setTodoQuestionnaire } from '../../../redux/features/generalSlice';
import { useNavigate } from 'react-router-dom';
import { CustomLoading } from '../../../shared/components/custom-loading';
import { VehicleCard } from '../components/vehicle-card';
import { Button, Menu, MenuProps, ToggleButton, ToggleButtonGroup, alpha, styled } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

export const SamplePrice = ({ vehicles, loading, handleSort }: any) => {

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [order, setOrder] = React.useState<any>();
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);
  const handleClose = () =>  setAnchorEl(null);

  const handlePrequalify = (): void => {
      dispatch(setTodoQuestionnaire(true));
      navigate('/insurance-questionnaire');
  };

  if(loading) return <CustomLoading />;
  
  return (
    <>
      <div className='flex flex-row md:items-center justify-between pt-10'>
        <h1 className='uppercase font-bold' style={{color: colors.primary}}>Sample Price</h1>
        <Button
          className='hidden'
          variant="outlined"
          onClick={handleClick}
          style={{borderColor: colors.primary, color: '#808080', borderRadius: 0, textTransform: 'none', height: 45, fontSize: 18}}
          endIcon={<KeyboardArrowDownIcon style={{marginLeft: 60}}/>}
        >
          Sort
        </Button>
        <StyledMenu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        >
          <div className={`flex flex-col justify-center items-center py-4`}>
            <h1 className='text-gray-500'>Options</h1>
            <ToggleButtonGroup
              size='small'
              orientation='vertical'
              style={{color: colors.primary}}
              value='ASC'
              exclusive
              onChange={(e: any) => {
                setAnchorEl(null);
                handleSort(e.target.value);
              }}
            >
              <ToggleButton value="ASC">Ascending</ToggleButton>
              <ToggleButton value="DESC">Descending</ToggleButton>
            </ToggleButtonGroup>
          </div>
        </StyledMenu>
      </div>
      <div className='flex flex-col gap-y-3 text-gray-500 mt-5 md:mt-5 mb-14 border-b pb-5' style={{fontSize: 15}}>
        <p>For a better understanding of how Avis iLease can benefit you, have a look at these sample pricing examples.</p>
        <p>PLEASE NOTE:</p>
          <div className='flex flex-row items-center gap-x-3'>
            <span className='rounded-full bg-gray-500 p-1'></span>
            <p>For actual quotations, you will need to be pre-qualified first.</p>
          </div>
          <div className='flex flex-row items-center gap-x-3'>
            <span className='rounded-full bg-gray-500 p-1'></span>
            <p>Your quote will include 12, 24, 36, 48 and 60 month lease options.</p>
          </div>
          <div className='flex flex-row items-center gap-x-3'>
            <span className='rounded-full bg-gray-500 p-1'></span>
            <p>Your contract will be tailored to your expected kilometre usage.</p>
          </div>
          <div className='flex flex-row items-center gap-x-3'>
            <span className='rounded-full bg-gray-500 p-1'></span>
            <p>The price quoted per month excludes compulsory insurance.</p>
          </div>
          <div className='flex flex-row items-center gap-x-3'>
            <span className='rounded-full bg-gray-500 p-1'></span>
            <p>Compulsory insurance will be qouted and charged separately and is required to complete the transaction.</p>
          </div>
          <p>
            <a href='https://www.aviscarsales.co.za' target='_blank' rel="noreferrer" className='hover:underline' style={{color: colors.primary}}>
              Click here
            </a> to view our nearly new vehicle catalogue. 
          </p>
      </div>
      <div className='grid grid-cols-1 md:grid md:grid-cols-4 gap-x-5 gap-y-16 mb-20'>
        {
          vehicles && vehicles.map((vehicle: any) => 
            <VehicleCard key={vehicle._id} vehicle={vehicle} handlePrequalify={handlePrequalify} />
          )
        }
      </div>
    </>
  );
};

const StyledMenu = styled((props: MenuProps) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      {...props}
    />
  ))(({ theme }) => ({
    '& .MuiPaper-root': {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      minWidth: 180,
      color:
        theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
      boxShadow:
        'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
      '& .MuiMenu-list': {
        padding: '4px 0',
      },
      '& .MuiMenuItem-root': {
        '& .MuiSvgIcon-root': {
          fontSize: 18,
          color: theme.palette.text.secondary,
          marginRight: theme.spacing(1.5),
        },
        '&:active': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            theme.palette.action.selectedOpacity,
          ),
        },
      },
    },
  }));
